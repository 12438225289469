import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { forgotPassword, resetEndpointDataAction } from '../../store/actions';
import { useThemeColor } from '../../utils/customHooks/useThemeColor';
import { toast } from 'react-toastify'
import NotificationMesage from '../Common/NotificationMesage';
import { Button } from 'react-bootstrap'
import { TextField } from '@material-ui/core'
import 'react-phone-number-input/style.css';
import './form.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';


const initialValues = {
  email: ''
};

export const ForgotPassword = () => {
  const themeColor = useThemeColor();
  const dispatch = useDispatch();
  let history = useHistory();

  const type = new URLSearchParams(window.location.search).get("type")
  const [mobile, setMobile] = useState('');
  const [mobileError, setMobileError] = useState('');

  const orgInfo = useSelector(state => state.orgInfo.responseData, shallowEqual);
  const forgotPasswordData = useSelector(state => state.forgotPasswordData, shallowEqual);
  const ipData = useSelector(state => state.ipData);
  // const primaryBgColor = { backgroundColor: orgInfo ? orgInfo?.orgColors?.primary : '', border: 'none' }

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Wrong email format').required('Required field')
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values) => {
      const { email } = values;
      if (type === 'email' && email) {
        const resetUrl = window.location.origin + '/reset_password';
        return dispatch(forgotPassword({ orgId: orgInfo.orgId, emailOrPhone: email, resetUrl }))
      }
     
    }
  });

  const handleSubmitData = () => {
    if (type === 'phoneNumber' && !mobile) return setMobileError('Mobile is required')
    if (type === 'phoneNumber' && mobile) {
      if (!isValidPhoneNumber(mobile)) return setMobileError('Invalid phone number')
      const resetUrl = window.location.origin + '/reset_password';
      return dispatch(forgotPassword({ orgId: orgInfo.orgId, emailOrPhone: mobile, resetUrl }))
    }
  }
  React.useEffect(() => {
    if (forgotPasswordData?.responseCode === '00') {
      // toast.success(<NotificationMesage message={forgotPasswordData?.responseMessage} responseStatus={forgotPasswordData?.responseCode} />, {
      //   onClose: () => {
      //   }
      // });
      // dispatch(resetEndpointDataAction);
      // history.push('/')
    }
    if (forgotPasswordData?.responseCode === '01') {
      // toast.error(<NotificationMesage message={forgotPasswordData?.responseMessage} responseStatus={forgotPasswordData?.responseCode} />, {
      //   onClose: () => dispatch(resetEndpointDataAction)
      // });
      // dispatch(resetEndpointDataAction)
      // history.push('/')
    }
  }, [forgotPasswordData?.responseCode])

  const handleBack = () => {
    dispatch(resetEndpointDataAction);
  }

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-5">
        <div>
          <span className="icon" style={themeColor.btnStyle}>
            <i className="fas fa-lock"></i>
          </span>
        </div>
        <h3 className="font-size-h1 mb-5">Forgot Password</h3>
        {forgotPasswordData?.responseCode !== '00'&& <p className="text-muted font-weight-bold">{type === 'email' &&  "Enter your email and we'll send you a link to reset it"} {type === 'phoneNumber' && "Enter your phone number and we'll text you a temporary password."}</p>}
      </div>
      {forgotPasswordData?.responseCode === '01' && <h5 className='text-center' style={{color: 'orangered'}}>{forgotPasswordData?.responseMessage}</h5>}
      {forgotPasswordData?.responseCode === '00' && <SuccessForgotPasswordPage successMessage={forgotPasswordData?.responseMessage} onBack={() => dispatch(resetEndpointDataAction)} />}
      {forgotPasswordData?.responseCode !== '00'&& <hr />}
      {/* end::Head */}

      {/*begin::Form*/}
      <>
        {forgotPasswordData?.responseCode !== '00' && <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework mt-10">

          {type === 'email' && <div className="fv-plugins-icon-container mb-10">
            <div className="position-relative">
              <TextField
                label="Email"
                type="email"
                className={` ${getInputClasses(
                  'email'
                )}`}
                name="email"
                variant="outlined"
                fullWidth
                error={formik.touched.email && formik.errors.email}
                {...formik.getFieldProps('email')}
              />
              {/* <i className="fas fa-envelope email-icon position-absolute"></i> */}
            </div>
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block errorText">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>}
          {type === 'phoneNumber' && <div className="fv-plugins-icon-container mb-10">
          <p style={{ marginBottom: "0.2rem" }}>Phone Number</p>
              <PhoneInput
                international
                withCountryCallingCode
                countryCallingCodeEditable={false}
                defaultCountry={ipData ? ipData.country_code : null}
                placeholder="Mobile"
                id="loginMobile"
                value={mobile}
                onChange={setMobile}
                className={`form-control text-dark-50 h-auto py-5 px-6 mobile ${(mobile && !isValidPhoneNumber(mobile)) ? 'is-invalid' : (mobile && isValidPhoneNumber(mobile)) ? 'is-valid' : ''}`}
                name="mobile"
                error={mobile ? (isValidPhoneNumber(mobile) ? undefined : 'Invalid mobile number') : 'Mobile number is required'}
              />
          {(type === 'phoneNumber' && mobile && !isValidPhoneNumber(mobile)) || mobileError ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{(mobile ? (isValidPhoneNumber(mobile) ? setMobileError('') : 'Invalid mobile number') : 'Mobile number is required') || mobileError}</div>
            </div>
          ) : null}
          </div>}
          <div className="d-flex justify-content-between align-items-center my-5">
            <div className="d-flex align-items-center">
              <Link className="text-dark-50 text-hover-primary" to="/">
                <Button variant="secondary" className="btn py-4 px-7" onClick={handleBack}>Back to Login</Button>
                {/* <span><i className="las la-angle-left mr-2"></i></span><span className="text-primary">Back to Login</span> */}
              </Link>
            </div>
            <button
              id="kt_forgot_password_submit"
              type="submit"
              disabled={forgotPasswordData.loading}
              style={themeColor.btnStyle}
              onClick={handleSubmitData}
              className={`btn font-weight-bold py-4 px-7`}>
              <span className="requestBtn">{forgotPasswordData.loading ? 'Please wait...' : 'Send Request'}</span>
              {forgotPasswordData.loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
        </form>}
      </>
    </div>
  );
}

const SuccessForgotPasswordPage = ({successMessage, onBack}) => {
  return (
    <div>
      <h5 className='text-center'>{successMessage}</h5>
      <div className="d-flex justify-content-center align-items-center my-5">
            <div className="d-flex align-items-center">
              <Link className="text-dark-50 text-hover-primary" to="/" onClick={onBack}>
                <Button variant="secondary" className="btn py-4 px-7">Back to Login</Button>
              </Link>
            </div>
          </div>
    </div>
  )
}